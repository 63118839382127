///////////////////////////////////////
//        THEME SWITCH BUTTON        //
///////////////////////////////////////

.themeSwitchButton {
  display: none;
  position: fixed;
  width: 1.5rem;
  top: 8.5rem;
  right: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  text-indent: -10000px;
  white-space: nowrap;
  border: 1px solid var(--iconFill);
  // box-shadow: 1px 1px 10px var(--accentGrey);
  cursor: pointer;
  background: none;
  transform: rotate(315deg);
  @include transition(all, 0.35s, cubic-bezier(0.72, 0.19, 0.39, 1.57));
  opacity: 1;
  z-index: 20;
  animation: 15s expandButton 5s forwards ease-in-out infinite;
  @include minMq($laptopBreak) {
    transform: rotate(270deg);
    right: 2.5vw;
    display: block;
    top: 50vh;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    @include transition(all, 0.35s, cubic-bezier(0.72, 0.19, 0.39, 1.57));
    opacity: 1;
    transform: rotate(360deg) scale(1);
    animation: none;
    background: var(--primaryColor);
  }
}

@keyframes expandButton {
  0% {
    transform: scale(0.9);
  }
  2% {
    transform: scale(1.1);
    background-color: var(--primaryColor);
  }
  4% {
    transform: scale(0.9);
  }
  6% {
    transform: scale(1.1);
  }
  8% {
    transform: scale(0.9);
  }
  10% {
    transform: scale(1.1);
  }
  12% {
    transform: scale(0.9);
  }
  14% {
    transform: scale(1.1);
  }
  16% {
    transform: scale(0.9);
    background-color: var(--primaryColor);
  }
  18% {
    transform: scale(1.1);
    background: none;
  }
  20% {
    transform: scale(1);
    background: none;
  }
  100% {
    transform: scale(1);
    background: none;
  }
}

.themeSwitchButton::after {
  position: absolute;
  top: -0.5rem;
  right: 4rem;
  margin-right: 0.5rem;
  content: "Turn to the dark side";
  border-radius: 5px;
  background: var(--themeButtonbg);
  text-indent: 0;
  color: var(--themeText);
  font-size: 1rem;
  padding: 0.5rem 1rem;
  @include transition(all, 0.35s, cubic-bezier(0.72, 0.19, 0.39, 1.57));
  opacity: 0;
  display: none;
  // transform: translateX(-50%);
}
.themeSwitchButton::before {
  position: absolute;
  content: "";
  width: 0px;
  height: 0px;
  top: 0;
  right: 3rem;
  margin-right: 0.5rem;
  border: 0.5rem solid transparent;
  border-left: 0.5rem solid var(--themeButtonbg);
  @include transition(all, 0.25s, ease-in-out);
  opacity: 0;
  display: none;
}

.themeSwitchButton:hover::after,
.themeSwitchButton:hover::before {
  display: block;
  opacity: 1;
  @include transition(all, 0.25s, ease-in-out);
}
.themeSwitchButton:hover::after {
  right: 2rem;
}
.themeSwitchButton:hover::before {
  right: 1rem;
}
html[dataTheme="dark"] .themeSwitchButton::after {
  @include transition(all, 0.25s, ease-in-out);
  content: "Light it up";
}
