///////////////////////////////////////
//        BACK TO TOP BUTTON         //
///////////////////////////////////////

.upButton {
  z-index: 501;
  display: block;
  height: 22px;
  width: 22px;
  position: fixed;
  top: 2rem;
  left: 3rem;
  opacity: 0;
  transform: rotate(0);
  border-radius: 100%;
  overflow: hidden;
  text-indent: -9999px;
  white-space: nowrap;
  @include transition(transform, 0.25s, cubic-bezier(1, 0.8, 0.5, 1));
  background: none;
  border: none;
  @include minMq($laptopBreak) {
    position: fixed;
    top: calc(100vh - 6vh);
    left: calc(100vw - 4vw);
  }
  & .iconFill {
    fill: var(--UI-Elements);
    @include minMq($laptopBreak) {
      fill: var(--iconFill);
    }
  }
  &:hover {
    // background-color: var(--primaryColor);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    cursor: pointer;
    @include transition(
      rotate 0.25s ease-in-out,
      opacity 0.3s 0s,
      visibility 0s 0s
    );
    @include transition(all 0.25s ease-in-out);

    & .iconMain {
      background: rgba(var(--primaryColor-rgb), 0.75);
      transition: 0.25 fill ease-in-out;
      .iconFill {
        transition: 0.25 fill ease-in-out;
        fill: white;
      }
    }
  }
  &:focus {
    outline: none;
  }
  &:active {
    @include transition(all, 0.05s, ease-in-out);
    // background-color: darken($complimentary-color, 20%);
    bottom: 1%;
  }
}
.upButton.upButtonvisible,
.upButton.upButtonfadeOut,
.no-touch {
  @include transition(all 0.25s ease-in-out);
}
.upButton.upButtonVisible {
  /* the button becomes visible */
  @include transition(all 0.5s ease-in-out);
  // visibility: visible;
  opacity: 1;
}
.upButton.upButtonFadeOut {
  /* if the user keeps scrolling down, the button is out of focus and becomes less visible */
  opacity: 0.5;
  @include transition(all, 0.25s, ease-in-out);
}
.upButton.upButtonFadeOut:hover {
  /* if the user keeps scrolling down, the button is out of focus and becomes less visible */
  opacity: 1;
}
.no-touch .upButton:hover {
  opacity: 1;
}
