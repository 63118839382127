html.colorTransition,
html.colorTransition *,
html.colorTransition *:before,
html.colorTransition *:after {
  transition: all 250ms !important;
  transition-delay: 0 !important;
}
html {
  /* global colors */
  --primaryColor-rgb: 1, 201, 221;
  --primaryColor-rgb: 32, 178, 170;
  --primaryColor: rgba(var(--primaryColor-rgb), 1);
  --primaryBg-rgb: 270, 270, 270;
  --primaryBg: rgba(var(--primaryBg-rgb), 1);
  --primaryHeaderBg: rgba(var(--primaryBg-rgb), 1);

  --primaryText: rgba(125, 125, 125, 1);
  --primaryLine: rgba(97, 97, 97, 0.3);
  --primaryButton: rgba(97, 97, 97, 0.65);

  --bodyText: rgba(0, 0, 0, 0.7);
  --overlay: rgba(0, 0, 0, 0.75);
  --primaryWhite: rgba(255, 255, 255, 1);
  --accentGrey: rgba(231, 248, 250, 1);
  --UI-Elements: rgba(0, 0, 0, 0.8);

  // --accentGrey: rgba(var(--primaryColor-rgb), 0.1);
  --iconFill: #6f7375;

  /* SECONDARY COLORS */
  --secondaryColor: #e52863;
  --secondaryText: rgba(148, 148, 148, 1);

  /* BUTTON */
  --primaryButtonText: rgba(162, 162, 162, 0.1);
  --primaryButtonBg: #efefef;
  --activeSelectionBg: rgba(162, 162, 162, 0.1);
  --primaryShadowBg: rgba(204, 204, 204, 0.7);

  --switchButton: rgba(0, 0, 0, 1);
  --reverseOutText: rgba(0, 0, 0, 0.5);
  --themeButtonbg: #292929;
  --themeText: #fff;
  --sectionText: rgba(0, 0, 0, 0.5);

  /* FOOTER */
  --footerBg: rgba(51, 51, 51, 1);
  --footerText: rgba(270, 270, 270, 0.5);
}

html[dataTheme="dark"] {
  /* global colors */
  --primaryColor-rgb: 230, 39, 99;
  --primaryColor: rgba(var(--primaryColor-rgb), 1);
  --primaryBg-rgb: 41, 41, 41;
  --primaryBg: rgba(var(--primaryBg-rgb), 1);
  --primaryHeaderBg: rgba(var(--primaryBg-rgb), 1);

  /* primaryHeaderBg */
  --primaryText: rgba(255, 255, 255, 0.8);
  --primaryLine: rgba(97, 97, 97, 0.4);
  --primaryButton: rgba(270, 270, 270, 0.65);

  --bodyText: rgba(270, 270, 270, 0.7);
  --primaryWhite: rgba(255, 255, 255, 1);
  --accentGrey: rgba(0, 0, 0, 0.375);
  --UI-Elements: rgba(255, 255, 255, 0.8);

  --iconFill: #6f7375;

  /* SECONDARY COLORS */
  --secondaryColor: #e52863;
  --secondaryText: rgba(255, 255, 255, 0.6);

  /* BUTTON */
  --primaryButtonText: #fff;
  --primaryBorderBg: #292929;
  --activeSelectionBg: rgba(0, 0, 0, 0.18);
  --primaryShadowBg: rgba(8, 8, 8, 0.7);

  --switchButton: rgba(270, 270, 270, 0.5);
  --reverseOutText: rgba(270, 270, 270, 0.75);
  --themeButtonbg: #fff;
  --themeText: #292929;
  --sectionText: rgba(270, 270, 270, 0.25);

  /* FOOTER */
  --footerBg: rgba(0, 0, 0, 0.225);
  --footerText: rgba(270, 270, 270, 0.5);
}
