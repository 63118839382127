// GENERAL COLOURS ///////////////////////////

$secondary-color: rgba(26, 25, 21, 1);
$complimentary-color: rgba(102, 102, 102, 1);
$complimentary-color: rgba(145, 145, 145, 1);

$accent-color: rgba(51, 51, 51, 1);
$light-accent-color: rgba(230, 228, 223, 1); // header light grey
$canvas-color: rgba(240, 240, 240, 1);

// FONT COLOURS ///////////////////////////
$primary-font-color: #969595; // main header grey

// FONTS ///////////////////////////
$bodyText: "Inter";
$mainHeader: "Inter";

$serifBody: "'PT Serif', serif";
$overlayText: "montserratsemi_bold";

$spanText: "playfair_displayitalic";
$mainheaderfont: "montserratregular";

// FONTS MIXINS ///////////////////////////

// INTRO PAGE HEADER
@mixin H1-Header(
  $fontFamily: $mainHeader,
  $size: clamp(3.2rem, 2.25vw, 3.8rem),
  $colour: var(--primaryColor),
  $weight: 700,
  $lh: 1.25,
  $ls: 0.0125rem
) {
  @if $fontFamily {
    font-family: $fontFamily;
  }
  @if $size {
    font-size: $size;
  }
  @if $colour {
    color: $colour;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $lh {
    line-height: $lh;
  }
  @if $ls {
    letter-spacing: $ls;
  }
}

@mixin H2-Header(
  $fontFamily: $mainHeader,
  $size: 2.4rem,
  $colour: var(--primaryColor),
  $weight: 300,
  $lh: 1.15,
  $ls: 0.025rem
) {
  @if $fontFamily {
    font-family: $fontFamily;
  }
  @if $size {
    font-size: $size;
  }
  @if $colour {
    color: $colour;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $lh {
    line-height: $lh;
  }
  @if $ls {
    letter-spacing: $ls;
  }
}
// SECTION SUB-HEADER
@mixin H3-Header(
  $fontFamily: $mainHeader,
  $size: clamp(2.2rem, 3vw, 2.4rem),
  $colour: var(--primaryColor),
  $weight: 700,
  $lh: 1.15,
  $ls: 0.025rem
) {
  @if $fontFamily {
    font-family: $fontFamily;
  }
  @if $size {
    font-size: $size;
  }
  @if $colour {
    color: $colour;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $lh {
    line-height: $lh;
  }
  @if $ls {
    letter-spacing: $ls;
  }
}
// FOLIO ITEM HEADER
@mixin H4-Header(
  $fontFamily: $mainHeader,
  $size: clamp(1.6rem, 1.2vw, 1.7rem),
  $colour: var(--primaryColor),
  $weight: 400,
  $lh: 1.15,
  $ls: 0.025rem
) {
  @if $fontFamily {
    font-family: $fontFamily;
  }
  @if $size {
    font-size: $size;
  }
  @if $colour {
    color: $colour;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $lh {
    line-height: $lh;
  }
  @if $ls {
    letter-spacing: $ls;
  }
}

//BUTTONS INTRO introPage
@mixin H5-Header(
  $fontFamily: $mainHeader,
  $size: clamp(1.6rem, 1vw, 1.8rem),
  $colour: var(--primaryColor),
  $weight: 800,
  $lh: 1.5,
  $ls: 0.05rem
) {
  @if $fontFamily {
    font-family: $fontFamily;
  }
  @if $size {
    font-size: $size;
  }
  @if $colour {
    color: $colour;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $lh {
    line-height: $lh;
  }
  @if $ls {
    letter-spacing: $ls;
  }
}
@mixin H6-Header(
  $fontFamily: $mainHeader,
  $size: 1.15rem,
  $colour: var(--primaryColor),
  $weight: 300,
  $lh: 1.25,
  $ls: 0.02rem
) {
  @if $fontFamily {
    font-family: $fontFamily;
  }
  @if $size {
    font-size: $size;
  }
  @if $colour {
    color: $colour;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $lh {
    line-height: $lh;
  }
  @if $ls {
    letter-spacing: $ls;
  }
}

// BODY TEXT /////////////////////////////////
@mixin BodyTextLarge(
  $fontFamily: $bodyText,
  $size: clamp(1.8rem, 2vw, 1.8rem),
  $colour: $complimentary-color,
  $weight: 400,
  $lh: 1.6,
  $ls: 0.015rem
) {
  @if $fontFamily {
    font-family: $fontFamily;
  }
  @if $size {
    font-size: $size;
  }
  @if $colour {
    color: $colour;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $lh {
    line-height: $lh;
  }
  @if $ls {
    letter-spacing: $ls;
  }
}
// SECTION BODY-TEXT
@mixin BodyTextSmall(
  $fontFamily: $bodyText,
  $size: clamp(1.45rem, 2vw, 1.6rem),
  $colour: $complimentary-color,
  $weight: 400,
  $lh: 1.6,
  $ls: 0.01rem
) {
  @if $fontFamily {
    font-family: $fontFamily;
  }
  @if $size {
    font-size: $size;
  }
  @if $colour {
    color: $colour;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $lh {
    line-height: $lh;
  }
  @if $ls {
    letter-spacing: $ls;
  }
}
@mixin BodyTextSerif(
  $fontFamily: $serifBody,
  $size: clamp(1.4rem, 2vw, 1.6rem),
  $colour: $complimentary-color,
  $weight: 400,
  $lh: 1.6,
  $ls: 0.05rem
) {
  @if $fontFamily {
    font-family: $fontFamily;
  }
  @if $size {
    font-size: $size;
  }
  @if $colour {
    color: $colour;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $lh {
    line-height: $lh;
  }
  @if $ls {
    letter-spacing: $ls;
  }
}
