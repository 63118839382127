* html {
  box-sizing: border-box;
  /*  font-size: rem....; define % for responsive design (default 100% = 16px - 62.5% = 10px)*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 62.5%;
}
html {
  font-size: 62.5%;
}
*,
*:before,
*:after {
  box-sizing: inherit;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
img,
p {
  width: 100%;
  height: auto;
  vertical-align: middle;
  display: block;
}
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-animation: fadein 1s ease-in;
  animation: fadein 1s forwards ease-in;
  list-style-type: none;
  line-height: 1.4;
  width: 100%;
  background: var(--primaryBg);
  color: var(--primaryText);
  font-weight: 400;
  font-family: Arial, serif;
  font-family: "Inter", sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.dispNone {
  display: none;
}

///////////////////////////////////////
//         MAIN STYLES            //
///////////////////////////////////////

.page {
  height: 100%;
  width: 100vw;

  /* transition: transform 0.3s ease-in-out; */
  transition: opacity 0.5s ease-in-out;
  margin: 0;
  padding: 0;
}
// .fade-exit {
//   opacity: 1;
// }
// .fade-exit-active {
//   opacity: 0;
//   transition: background 0.5s ease-out;
// }

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms ease-out;
}

/* slide enter */
.slide-enter {
  opacity: 0;
  transform: translateY(30px);
  z-index: 1;
}
.slide-enter.slide-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms linear 150ms, transform 500ms ease-in-out 300ms;
}

/* slide exit */
.slide-exit {
  opacity: 1;
  transform: translateY(0);
}
.slide-exit.slide-exit-active {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 300ms linear, transform 500ms ease-out;
}
.slide-exit-done {
  opacity: 0;
}

.appContainer {
  background: var(--primaryBg);
  width: 100vw;
}
.page_container {
  position: relative;
  @include flexbox();
  @include flex-direction(column);
  width: 100%;
  margin: 0 auto;
}
.wrapper {
  position: relative;
  width: 80vw;
  margin: 0 auto;
  @include flexbox();
  z-index: 1;
}

///////////////////////////////////////
//         MAIN TYPE STYLES          //
///////////////////////////////////////

.sectionSubHeader {
  position: relative;
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 2rem;
  @include H3-Header;
  @include maxMq($laptopBreak) {
    font-size: clamp(2.8rem, 3vw, 3.6rem);
    margin-bottom: 0;
  }
}
.sectionBody {
  text-align: center;
  margin-top: 2rem;
  @include BodyTextSmall;
  @include minMq($laptopBreak) {
    width: 40vw;
    letter-spacing: 0.015rem;
    line-height: 1.6;
  }
}

// DOUBLE LINES BELOW SECTION SUBHEADER
.sectionSubHeader::after,
.sectionSubHeader::before {
  content: "";
  width: 40%;
  text-align: center;
  margin: 0 auto;
  margin-top: 1rem;
  position: absolute;
  left: -50%;
  right: -50%;
  top: 100%;
  z-index: 3;
  background: var(--primaryColor);
  width: clamp(3rem, 2vw, 4rem);
  height: clamp(3rem, 2vw, 4rem);
  clip-path: polygon(0 0, 100% 15%, 100% 20%, 0 5%);
  @include maxMq($laptopBreak) {
    display: none;
  }
}
.sectionSubHeader::before {
  transform: translateY(0.5rem);
}

// NO MATCH PAGE

// WHEN PRESSED
.NoMatch {
  @include flexColumnCentered;
  height: 100vh;
  width: 100vw;
  background-color: var(--primaryColor);
}
.NoMatchContainer {
  @include flexColumnCentered;
  width: 90vw;
  @include minMq($mobileBreak) {
    width: 50vw;
  }
  h2 {
    @include H3-Header;
    color: white;
    margin-bottom: 2rem;
    text-align: center;
  }
  code {
    font-style: italic;
    padding: 0.5rem;
  }
  & button {
    @include H5-Header;
    color: $light-accent-color;
    outline: none;
    border: none;
    background: rgba(0, 0, 0, 0);
    padding: 1rem 2rem;
    border-radius: 16px;
    border: 1px solid $light-accent-color;
    transition: 0.25s background ease-in-out;
    &:hover {
      background: $light-accent-color;
      color: var(--primaryColor);
    }
    & a {
      background: none;
      text-decoration: none;
      color: inherit;
    }
  }
}
