///////////////////////////////////////
//   SKIP TO NEXT SECTION BUTTON     //
///////////////////////////////////////
// for .downButton > see upButton above
.downButton {
  text-indent: 10000px;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  position: absolute;
  bottom: 3%;
  left: 50%;
  transform: translateX(-50%);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 500px;
  border: 1px solid var(--primaryButton);
  @include transition(all, 0.2s, ease-in-out);
  z-index: 1000;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: 1px solid var(--primaryButton);
    border-radius: 100%;
    margin: 0.7rem;
  }
  &:hover {
    @include transition(all, 0.2s, ease-in-out);
    cursor: pointer;
    bottom: 2.75%;
    background-color: var(--primaryColor);
    &::after {
      @include transition(all, 0.2s, ease-in-out);
      background: rgba(270, 270, 270, 1);
      clip-path: polygon(50% 100%, 0 30%, 100% 30%);
      background: white;
    }
  }
  &:active {
    @include transition(all, 0.05s, ease-in-out);
    background-color: var(--accentGrey);
    bottom: 2.5%;
  }
}
