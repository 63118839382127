///////////////////////////////////////
//          LOADER STYLES            //
///////////////////////////////////////

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
  &__loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 120px;
    height: 120px;
    transform: translate(-50%, -50%);
    margin: -60px 0 0 -60px;
    border-radius: 50%;
    border: 1px solid transparent;
    border-top-color: #f8f8f8;
    -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
    z-index: 1001;
    &:before {
      content: "";
      position: absolute;
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: $complimentary-color;
      -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
      animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
    }
    &:after {
      content: "";
      position: absolute;
      top: 15px;
      left: 15px;
      right: 15px;
      bottom: 15px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: var(--primaryColor);
      -webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
      animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
    }
  }
  &__section {
    position: fixed;
    top: 0;
    width: 55%;
    height: 100%;
    background: $accent-color;
    z-index: 1000;
    -webkit-transform: translateX(0); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: translateX(0); /* IE 9 */
    transform: translateX(0); /* Firefox 16+, IE 10+, Opera */
  }
  &__section.section--left {
    left: 0;
    clip-path: polygon(0 0, 100% 0, 95% 100%, 0% 100%);
  }
  &__section.section--right {
    right: 0;
    clip-path: polygon(5% 0, 100% 0%, 100% 100%, 0% 100%);
  }
  &__loaderText {
    font-size: 1.4rem;
    color: #f8f8f8;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    margin: auto;
    z-index: 1005;
    display: block;
    // -webkit-animation: glow 1s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
    // animation: glow 1s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
    // -webkit-animation: glow 0.5s ease-out;
    // -moz-animation: glow 0.5s ease-out;
    // -o-animation: glow 0.5s ease-out;
    // animation: glow 0.5s ease-out;
    -webkit-animation: glow 1s ease-in-out infinite;
    -moz-animation: glow 1s ease-in-out infinite;
    -o-animation: glow 1s ease-in-out infinite;
    animation: glow 1s ease-in-out infinite;
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg); /* IE 9 */
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg); /* IE 9 */
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg); /* IE 9 */
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg); /* IE 9 */
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}

// TEXT BELOW ////////////////////////////////////////////

@-webkit-keyframes glow {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes glow {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes glow {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes glow {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

// When Loaded remove screen left & right /////////

.loaded .loader-wrapper__section.section--left {
  -webkit-transform: translateX(-100%); /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(-100%); /* IE 9 */
  transform: translateX(-100%); /* Firefox 16+, IE 10+, Opera */

  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded .loader-wrapper__section.section--right {
  -webkit-transform: translateX(100%); /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(100%); /* IE 9 */
  transform: translateX(100%); /* Firefox 16+, IE 10+, Opera */

  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded .loader-wrapper__loader {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.loaded .loader-wrapper__loaderText {
  opacity: 0;
  display: none;
  -webkit-transition: all 0 ease-out;
  transition: all 0 ease-out;
}
.loaded .loader-wrapper {
  visibility: hidden;

  -webkit-transform: translateY(-100%); /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateY(-100%); /* IE 9 */
  transform: translateY(-100%); /* Firefox 16+, IE 10+, Opera */

  -webkit-transition: all 0.3s 1s ease-out;
  transition: all 0.3s 1s ease-out;
}

// JavaScript Turned Off */
.no-js .loader-wrapper {
  display: none;
}
.no-js h1 {
  color: #222222;
}

#uploader {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  z-index: 9999;
  opacity: 1;
}
.loaded #uploader {
  opacity: 0;
  display: none;
  -webkit-transition: all 0 ease-out;
  transition: all 0 ease-out;
}

// Image replacement /////////////////

.ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;
  /* IE 6/7 fallback */
  *text-indent: -9999px;
}
.ir:before {
  content: "";
  display: block;
  width: 0;
  height: 150%;
}

// Hide from both screenreaders and browsers: h5bp.com/u

.hidden {
  display: none !important;
  visibility: hidden;
}

// Hide only visually, but have it available for screenreaders: h5bp.com/v

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// Extends the .visuallyhidden class to allow the element to be focusable
// when navigated to via the keyboard: h5bp.com/p

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

// Hide visually and from screenreaders, but maintain layout

.invisible {
  visibility: hidden;
}

/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */

.clearfix:before,
.clearfix:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}

.clearfix:after {
  clear: both;
}

/*
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */

.clearfix {
  *zoom: 1;
}

/* ==========================================================================
   EXAMPLE Media Queries for Responsive Design.
   These examples override the primary ('mobile first') styles.
   Modify as content requires.
   ========================================================================== */

@media only screen and (min-width: 35em) {
  /* Style adjustments for viewports that meet the condition */
}

@media print,
  (-o-min-device-pixel-ratio: 5/4),
  (-webkit-min-device-pixel-ratio: 1.25),
  (min-resolution: 120dpi) {
  /* Style adjustments for high resolution devices */
}
