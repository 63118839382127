// ANIMATION /////////////////

$fadeInAnimation: 0.35s fadingIn 0.5s forwards ease-in-out;
$fadeInAnimation: 0.35s fadingIn 0.5s forwards
  cubic-bezier(0.21, 0.81, 0.83, 0.67);
$fadeInAnimation: 0.35s fadingIn 0.5s forwards
  cubic-bezier(0.25, 0.75, 0.5, 1.25);

// @keyframes borderLeft {
//   0% {
//     box-shadow: -10px 0px 0px 0px rgba(270, 270, 270, 0.15);
//   }
//   100% {
//     box-shadow: -1px 0px 0px 0px var(--primaryText);
//   }
// }

@keyframes borderLeft {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    border-left: 1px solid var(--primaryText);
  }
}
// @keyframes borderRight {
//   0% {
//     box-shadow: 10px 0px 0px 0px rgba(270, 270, 270, 0.15);
//   }
//   100% {
//     box-shadow: 1px 0px 0px 0px var(--primaryText);
//   }
// }
@keyframes borderRight {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    border-right: 1px solid var(--primaryText);
  }
}
@keyframes slidingIn {
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}
@keyframes slideUp {
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes slideDown {
  100% {
    transform: translateY(60%);
    opacity: 0;
  }
}
@keyframes desBlockSlideIn {
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
@keyframes slidingInDev {
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}
@keyframes slideUpDev {
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes devSlideIn {
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
@keyframes animateBg {
  100% {
    opacity: 1;
  }
}
@keyframes fadingIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeIn50 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
