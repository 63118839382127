///////////////////////////////////////
//         PORTFOLIO GRID            //
///////////////////////////////////////

.portfoliotext {
  @include flexbox();
  position: relative;
  text-align: center;
  padding: 15vh 0 5vh 0;
  & .wrapper {
    @include flexColumnCentered;
    @include maxMq($mobileBreak) {
      margin-top: 0;
    }
  }
  & .mobileMessage {
    font-size: clamp(1.2rem, 1vw, 1.4rem);
    margin-top: 1.5rem;
    @include minMq($mobileBreak) {
      display: none;
    }
  }
}

// FOLIO IMAGE GRID
.portfolioGrid {
  width: 80vw;
  @include flexbox();
  margin: 0 auto;
  @include flex-direction(column);
  @include minMq($laptopBreak) {
    @include display-grid;
    width: 80vw;
    // available column width 80vw - (11 x 1.5 for gaps) = 80vw - 16.5vw = 62.5
    grid-template-columns: repeat(12, calc(62.5vw / 12));
    grid-template-rows: calc(80vw / 12);
    grid-gap: 1.5vw;
  }
  // @include minMq(1400px) {
  //   width: $width75;
  //   grid-template-columns: repeat(12, calc($width75 / 12));
  //   grid-auto-rows: calc($width75 / 12);
  // }
  & .portfolioItem {
    overflow: hidden;
    position: relative;
    width: 100%;
    border-radius: 6px;
    margin-bottom: 3rem;
    max-height: $width90;
    @include minMq($laptopBreak) {
      margin-bottom: 0;
    }
    & .portfolioListItem a::before {
      /* adds same gradient overlay on all images */
      @include pseudoContent();
      opacity: 0.5;
      z-index: 1;
      background: linear-gradient(
        rgba(0, 0, 0, 0.95),
        rgba(67, 17, 51, 0.95),
        #000320
      );
      background-blend-mode: multiply;
      border-radius: 6px;
      overflow: hidden;
    }
    &:nth-child(1) {
      // eurokeys
      grid-row: 1 / span 4;
      grid-column: 1 / span 4;
    }
    &:nth-child(2) {
      // girp
      grid-row: 5 / span 4;
      grid-column: 1 / span 4;
    }
    &:nth-child(3) {
      // wise
      grid-row: 1 / span 5;
      grid-column: 5 / span 4;
    }
    &:nth-child(4) {
      // bonaventura
      grid-row: 6 / span 3;
      grid-column: 5 / span 4;
    }
    &:nth-child(5) {
      // personal
      grid-row: 1 / span 3;
      grid-column: 9 / span 4;
    }
    &:nth-child(6) {
      // skytale
      grid-row: 4 / span 5;
      grid-column: 9 / span 4;
    }
    &:nth-child(7) {
      // braun
      grid-row: 9 / span 4;
      grid-column: 1 / span 6;
    }
    &:nth-child(8) {
      // buffalo
      grid-row: 13 / span 4;
      grid-column: 1 / span 6;
    }
    &:nth-child(9) {
      // concerto
      grid-row: 9 / span 5;
      grid-column: 7 / span 3;
    }
    &:nth-child(10) {
      // identity
      grid-row: 14 / span 3;
      grid-column: 7 / span 3;
    }
    &:nth-child(11) {
      // icwa
      grid-row: 9 / span 3;
      grid-column: 10 / span 3;
    }
    &:nth-child(12) {
      // berlin_poster
      grid-row: 12 / span 5;
      grid-column: 10 / span 3;
    }
  }
}

.overlay li a div {
  z-index: 1;
  opacity: 0.5;
  transform: translateY(100%);
  @include transition(transform, 0.25s, ease-in-out);
}
// OVERLAY EFFECT ON HOVER
.overlay {
  line-height: 1.5rem;
  list-style: none;
  display: block;
  position: relative;
  @include transition(transform, 0.5s, ease-in-out);
  text-align: center;
  & li {
    position: relative;
    &:hover > a div h4 {
      animation: 0.375s transY 0.1s forwards;
    }
    &:hover > a div h5 {
      animation: 0.3s transY 0.25s forwards;
    }
    &:hover > a div h6 {
      animation: 0.65s reveal 0.5s forwards;
    }
    &:hover > a div {
      @include transition(transform, 0.25s, ease-in-out);
      transform: translateY(0);
      opacity: 1;
      top: 0;
    }
  }
  li a,
  li a img {
    width: 100%;
    display: block;
    position: relative;
  }
  li a {
    overflow: hidden;
  }
  // PROJECT TEXT CONTAINER
  li a div {
    position: absolute;
    @include flexColumnCentered;
    background: var(--overlay);
    opacity: 0.8;
    background-blend-mode: multiply;
    width: 100%;
    height: 100%;
    padding: 0 8vw;
    text-align: center;
    @include minMq($laptopBreak) {
      padding: 0 2vw;
    }
    // PROJECT NAME
    & h4 {
      @include H4-Header;
      font-size: clamp(1.5rem, 1vw, 1.2rem);
      padding: 1rem 0 1rem 0;
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1), 0 -1px 0 rgba(255, 255, 255, 0.3),
        0 -1px 0 rgba(0, 0, 0, 0.1), 0 1px 0 rgba(255, 255, 255, 0.3);
      text-transform: uppercase;
      transform: translateY(-80px);
      opacity: 0;
      // CLIENT TEXT
      span {
        // font-family: $serifBody;
        color: $light-accent-color;
        font-style: Italic;
        font-weight: 300;
        text-transform: lowercase;
      }
    }
    // PROJECT BODY TEXT
    & h5 {
      @include BodyTextSmall;
      font-size: clamp(1.4rem, 0.5vw, 1.6rem);
      color: $light-accent-color;
      font-weight: 300;
      position: relative;
      padding: 2rem 0 0.5rem 0;
      transform: translateY(30px);
      opacity: 0;
      span {
        colour: var(--primaryColor);
      }
    }
    // CLICK FOR MORE TEXT
    & h6 {
      @include BodyTextSmall;
      @include transition(opacity, 0.25s, ease-in-out);
      opacity: 0;
      color: var(--primaryColor);
      cursor: pointer;
      margin: 1rem 0 1rem 0;
    }
    // ARROW ON HOVER
    .nav_arrow {
      width: 1.5rem;
      margin: 0 auto;
      position: relative;
      visibility: hidden;
      transform: translateY(-1.5rem) rotate(0deg);
      margin-top: 0.5rem;
      & .iconFill {
        fill: $light-accent-color;
      }
    }
    &:hover .nav_arrow {
      cursor: pointer !important;
      animation: 0.4s rotateMove 0.25s ease-in-out forwards;
    }
  }
}

// ANIMATIONS
@keyframes rotateMove {
  0% {
    opacity: 0;
  }
  100% {
    transform: translateY(0%) rotate(180deg);
    opacity: 1;
    visibility: visible;
  }
}
@keyframes reveal {
  0% {
    opacity: 0;
    // visibility: hidden;
  }
  100% {
    opacity: 1;
    // visibility: visible;
  }
}
@keyframes transY {
  0% {
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
