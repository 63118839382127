#arrowDown {
  transform: translateY(-100%) scale(1);
  opacity: 0;
  animation: 2.25s rollingArrow 0.5s infinite ease-in-out;
  transform-origin: center;
  // &:hover {
  //   transition: 0.15s all ease-in-out;
  //   animation: 2.25s zoomInArrow 0.5s infinite ease-in-out;
  // }
}

@keyframes rollingArrow {
  0% {
    opacity: 0;
    transform: translateY(-100%) scale(1);
  }
  40% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
  80% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
  100% {
    opacity: 0;
    transform: translateY(100%) scale(1);
  }
}
@keyframes zoomInArrow {
  0% {
    opacity: 0;
    transform: translateY(0) scale(1);
  }
  20% {
    opacity: 1;
    transform: translateY(0) scale(1.2);
  }
  40% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
  60% {
    opacity: 1;
    transform: translateY(0) scale(1.2);
  }
  80% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1.2);
  }
}
