///////////////////////////////////////
//             HEADER                //
///////////////////////////////////////

.headerContainer {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 500;
  box-shadow: 1px 1px 8px 4px rgba(0, 0, 0, 0.18);
  background: rgba(var(--primaryBg-rgb), 0.3);
  @include minMq($laptopBreak) {
    background: none;
    box-shadow: none;
  }
  &::before {
    @include pseudoContent;
    background: linear-gradient(
      to bottom,
      rgba(245, 246, 252, 0.52),
      rgba(117, 19, 93, 0.73)
    );
    z-index: 0;
    opacity: 0.35;
    background-blend-mode: multiply;
    @include minMq($laptopBreak) {
      opacity: 0.15;
      background-blend-mode: none;
      background: none;
    }
  }
  &::after {
    @include pseudoContent;
    background: black;
    z-index: 0;
    opacity: 0.5;
    background-blend-mode: multiply;
    @include minMq($laptopBreak) {
      opacity: 0.15;
      background-blend-mode: none;
      background: none;
    }
    z-index: -1;
  }

  & .headerWrapper {
    padding: 2rem 0 2rem 0;
    width: 90%;
    position: relative;
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);
    @include transition(all, 0.25s, ease-in-out);
    margin: 0 auto;
    @include minMq($laptopBreak) {
      width: 95vw;
      @include justify-content(space-between);
      @include flexbox();
    }
    & .Logo {
      @include flexRowCentered position: relative;
      &__logoContainer {
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        @include flexColumnCentered;
        width: clamp(4rem, 6vw, 7rem);
        height: clamp(4rem, 6vw, 7rem);
        max-width: 60px;
        @include transition(all, 0.2s, ease-in-out);
        @include minMq($laptopBreak) {
          position: relative;
          height: 6rem;
        }
      }
      &__logoContainer svg {
        width: 100%;
        margin: 0 auto;
        max-height: 30px;
        @include transition(width, 0.4s, ease-in-out);
        @include minMq($laptopBreak) {
          max-width: 8rem;
          max-height: 8rem;
        }
        // classes from logo svg
        .st0 {
          stroke: #000000;
          stroke-width: 0.112;
          stroke-miterlimit: 10;
        }
        .st1 {
          fill: rgba(255, 255, 255, 0.89);
        }
        .st2 {
          fill: var(--primaryColor);
        }
        .st3 {
          opacity: 0.3;
          enable-background: new;
        }
      }
    }
  }
}

///////////////////////////////////////
//           NAV > MOBILE            //
///////////////////////////////////////

.mobile-btn {
  height: 20px;
  width: 20px;
  z-index: 10001;
  @include transition(all, 0.2s, ease-in-out);
  @include flexColSpaceBewteen;
  &:hover {
    cursor: pointer;
  }
  span {
    height: 1px;
    background: var(--UI-Elements);
    z-index: 10000;
    transform-origin: center center;
  }
  &.active ~ .toggle-btn span {
    background: #000;
  }
}
#mobileMenu ~ .mobile-btn span:nth-child(1) {
  // show middle bar
  @include transition(all, 0.2s, ease-in-out);
  transform: translate(0, 0) rotate(0deg);
}
#mobileMenu.active ~ .mobile-btn span:nth-child(1) {
  transform: translate(0, 9px) rotate(45deg);
  background: var(--UI-Elements);
  background: white;
}
// show middle bar
#mobileMenu ~ .mobile-btn span:nth-child(2) {
  @include transition(all, 0.2s, ease-in-out);
  opacity: 1;
}
// hide middle bar on click
#mobileMenu.active ~ .mobile-btn span:nth-child(2) {
  opacity: 0;
}
#mobileMenu ~ .mobile-btn span:nth-child(3) {
  @include transition(all, 0.2s, ease-in-out);
  transform: translate(0, 0) rotate(0deg);
}
#mobileMenu.active ~ .mobile-btn span:nth-child(3) {
  transform: translate(0, -10px) rotate(-45deg);
  background: var(--UI-Elements);
  background: white;
}
.mobileNav {
  z-index: 10000;
  // .navLink {
  //   display: flex;
  //   transform: scale(1.5);
  // }
  & #mobileMenu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    transform: translateX(-100%);
    @include transition(transform, 0.25s, ease-out);
    @include flexColumnCentered;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    background: rgba(0, 0, 0, 0.85);
    &.active {
      transform: translateX(0);
    }
    li {
      @include flexColumnCentered;
      width: 100%;
      height: calc(100vh / 3);
      text-align: center;
      border-bottom: 1px solid var(--primaryColor);
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        @include transition(background, 0.2s, ease-in-out);
        cursor: pointer;
        & .iconFill {
          fill: var(--secondaryText);
          fill: white;
        }
        & .mobile-btn span {
          background: var(--secondaryText);
        }
      }
      a {
        text-transform: uppercase;
        text-decoration: none;
        text-align: center;
        letter-spacing: 0.5rem;
        height: calc(100vh / 3);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        // transform: scale(1.5);
        @include transition(color, 0.2s, ease-in-out);
        cursor: pointer;
        &:hover {
          @include transition(color, 0.2s, ease-in-out);
          color: var(--primaryColor);
          letter-spacing: 1rem;
          height: calc(100vh / 3);
          cursor: pointer;
          background: rgba(var(--primaryColor-rgb), 0.5);
        }
        svg {
          width: 30px;
          max-height: 30px;
          height: auto;
        }
      }
    }
    .iconFill {
      fill: var(--primaryColor);
      // width: 30vw;
      // height: 30vh;
    }
  }
}

.desktopNav {
  display: none;
}

///////////////////////////////////////
//          NAV > DESKTOP            //
///////////////////////////////////////

@media all and (min-width: $laptopBreak) {
  .mobileNav {
    display: none;
  }
  .navTop {
    position: relative;
    @include flexbox();
    @include justify-content(space-between);
    @include flex-direction(column);
    @include align-items(flex-end);
    transition: margin-top 0.4s;
    @include transition(all, 0.2s, ease-in-out);
    z-index: 10002;
    ul {
      list-style: none;
      text-align: right;
      li {
        @include transition(all, 0.2s, ease-in-out);
        padding-bottom: 2rem;
        margin: 0 auto;
        text-align: center;
        &:last-child {
          padding-bottom: 0;
        }
      }
      .navLink {
        position: relative;
        color: var(--secondaryText);
        margin: 0 auto;
        font-weight: 800;
        text-transform: uppercase;
        text-decoration: none;
        list-style-type: none;
        pointer-events: all;
        text-align: center;
        @include transition(all, 0.15s, ease-in-out);
        // width: 2.5rem;
        fill: transparent;
        // height: 3rem;
        &.portfolioIcon::before {
          content: "projects";
        }
        &.contact::before {
          content: "connect";
        }
        &::before {
          position: absolute;
          left: -25px;
          top: -6px;
          right: 0;
          content: "about";
          color: var(--primaryColor);
          letter-spacing: 0.135rem;
          font-family: "Inter", sans-serif;
          font-size: clamp(0.75rem, 0.8vw, 0.9rem);
          width: 1.5rem;
          height: 20px;
          transition: 0.15s transform ease-in-out;
          opacity: 0;
          @include flexbox();
          @include justify-content(flex-end);
          transform: translateX(-50%);
          &.aboutIcon,
          &.projectsIcon,
          &.connectIcon {
            margin: 0 auto;
            width: 20vw;
            text-align: center;
            @include minMq($laptopBreak) {
              width: clamp(1.25rem, 2vw, 2.25rem);
            }
          }
        }
        &:hover::before {
          transition: 0.15s transform ease-in-out;
          transform: translateX(0%);
          opacity: 1;
        }
        &.navLink:hover .iconCenterFill {
          fill: var(--primaryColor);
          opacity: 0.5;
          transition: 0.25 fill ease-in-out;
        }
      }
      .iconFill {
        fill: var(--iconFill);
      }
    }
  }
}
