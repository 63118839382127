// PROJECT PAGES //////////////////////

// For images in portfolio using nth child, repeats styling after every 4 images
// PROJECT DETAILS ////////////////////

.portfolio {
  width: 99.9%;
  margin: 0 auto;
  position: relative;
  @include flexbox();
  @include flex-direction(column);
  & .websiteContainer {
    padding: 0 0 0;
  }
  .folio {
    position: relative;
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(space-between);
    @include align-items(flex-start);
    @include minMq($laptopBreak) {
      @include flex-direction(row);
    }
    text-align: left;
    z-index: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)";
    opacity: 1;
    @include transition(all, 0.4s, ease-out);
    margin: 16rem 0 3rem 0;
    @include minMq($laptopBreak) {
      margin: 12rem 0 3rem 0;
    }

    h5 {
      color: var(--secondaryText);
      position: relative;
      width: 100%;
      font-size: clamp(1.3rem, 2vw, 1.3rem);
      line-height: 1.6;
      padding: 0 2rem 0 2rem;
      font-weight: 400;
      border-left: 1px solid var(--primaryLine);
      // border-right: 1px solid var(--primaryLine);
      opacity: 1;
      transition: 0.25s all ease-in-out;
      margin-top: 1.5rem;
      @include minMq($laptopBreak) {
        width: 50vw;
        margin-top: 3rem;
      }
      & span {
        color: var(--primaryColor);
        font-weight: 700;
        // font-size: 1.3rem;
        display: inline;
      }
    }
    .linksContainer {
      @include flexbox();
      @include flex-direction(row);
      @include justify-content(flex-start);
      @include align-items(center);
      border-left: 1px solid var(--primaryLine);
      margin-top: 1.5rem;
      // padding: 1.5rem 0;
      .externalPageLinks {
        margin-left: 2rem;
        @include flexbox();
        @include flex-direction(row);
        @include justify-content(flex-start);
        @include align-items(center);
        text-decoration: none;
        border-radius: 4px;
        color: var(--primaryColor);
        opacity: 1;
        font-size: clamp(1.3rem, 2vw, 1.3rem);
        font-weight: 700;

        .externalLinks {
          margin-right: 1rem;
        }
        &:hover {
          transition: 0.125s all ease-in-out;
          // background: rgba(var(--primaryColor-rgb), 0.5);
          fill: var(--primaryColor);
        }

        & svg {
          margin-left: 1rem;
          fill: var(--secondaryText);
          width: 15px;
          &:hover {
            transition: 0.125s all ease-in-out;
            fill: var(--primaryColor);
          }
        }
      }
    }
    & #linkHome {
      margin-left: 2rem;
      @include flexbox();
      @include flex-direction(row);
      @include justify-content(flex-start);
      @include align-items(center);
      text-decoration: none;
      border-radius: 4px;
      color: var(--primaryColor);
      opacity: 1;
      font-size: clamp(1.3rem, 2vw, 1.3rem);
      font-weight: 700;
      z-index: 5000;
      margin-top: 1.5rem;
      @include minMq($laptopBreak) {
        margin-top: 3rem;
      }
      .homeFill {
        fill: var(--secondaryText);
        &:hover {
          fill: var(--primaryColor);
        }
      }
      & svg {
        transform: rotate(180deg);
        margin-left: 1rem;
        width: 15px;
      }
    }
  }

  .projectImagesContainer {
    & h6 {
      clear: both;
      display: inline-block;
      margin: 0;
      padding: 0.5rem 1.5rem 0.5rem 1rem;
      position: relative;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.2;
      font-family: $bodyText, Helvetica, Arial, sans-serif;
      background-color: var(--primaryColor);
      border-bottom: none;
      color: $light-accent-color;
      &:first-child {
        margin-top: 5rem;
      }
    }
  }
}

// PORTFOLIO IMAGES
.projectImagesContainer {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 2rem;

  @include minMq($laptopBreak) {
    &:nth-of-type(4n + 1) {
      & .projectImagesContainer__wrapper .projText {
        bottom: 2rem;
        right: 2rem;
      }
    }
    &:nth-of-type(4n + 2) {
      .projectImagesContainer__container {
        left: 10%;
        bottom: 2rem;
      }
      & .projectImagesContainer__wrapper .textureDiv {
        left: 0;
        bottom: 0;
      }
      & .projectImagesContainer__wrapper .projText {
        left: 2rem;
        bottom: 2rem;
      }
    }
    &:nth-of-type(4n + 3) {
      .projectImagesContainer__container {
        margin-top: 10%;
        left: 0;
      }
      & .projectImagesContainer__wrapper .textureDiv {
        top: 0;
        right: 0;
      }
      & .projectImagesContainer__wrapper .projText {
        right: 2rem;
        top: 2rem;
      }
    }
    &:nth-of-type(4n + 4) {
      .projectImagesContainer__container {
        margin-top: 10%;
        left: 10%;
      }
      & .projectImagesContainer__wrapper .textureDiv {
        top: 0;
        left: 0;
      }
      & .projectImagesContainer__wrapper .projText {
        left: 2rem;
        top: 2rem;
      }
    }
  }

  & .descriptionText {
    display: none;
    font-size: 1rem;
    text-transform: uppercase;
    color: var(--primaryWhite);
    background: var(--primaryColor);
    padding: 0.35rem 0.85rem;
    font-weight: 700;
    letter-spacing: 0.05rem;
    width: auto;
    z-index: 10;
    margin-bottom: 1rem;
    top: 1rem;
    left: 1rem;
  }
  & .additionalText {
    display: none;
    position: relative;
    margin-top: 0.75rem;
    color: var(--secondaryText);
    font-size: 1.4rem;
    font-weight: 700;
    transition: all 0.3s ease-out;
    z-index: 10;
    margin-bottom: 4rem;
  }
  & .descriptionText,
  & .additionalText {
    @include minMq($laptopBreak) {
      display: block;
    }
  }
  &__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 0;
    margin-bottom: 3rem;
    padding-bottom: 0;
    .textureDiv {
      display: none;
      position: absolute;
      width: 90%;
      height: 90%;
      bottom: 0;
      right: 0;
      z-index: 0;
      background: -webkit-repeating-linear-gradient(
        -55deg,
        rgba(var(--primaryColor-rgb), 0.15),
        rgba(var(--primaryColor-rgb), 0.15) 0.3rem,
        rgba(255, 255, 255, 0) 0.3rem,
        rgba(255, 255, 255, 0) 0.6rem
      );
      @include minMq($laptopBreak) {
        display: block;
      }
    }
    @include minMq($laptopBreak) {
      padding-bottom: 8rem;
      margin-bottom: 6rem;
    }
    & .projText {
      display: none;
      z-index: 100;
      position: absolute;
      margin: 0;
      color: var(--primaryText);
      background: var(--primaryBg);
      font-weight: 400;
      font-size: calc(0.4rem + 0.35vw);
      padding: 0.6rem 1.5rem;
      width: auto;
      box-shadow: 3px 3px 0 rgba(var(--primaryColor-rgb), 0.25);
      @include minMq($laptopBreak) {
        display: flex;
      }
    }
  }
  &__container {
    position: relative;
    width: 100%;
    height: auto;
    z-index: 1;
    @include minMq($laptopBreak) {
      width: calc(90% - 1px);
      width: 0 auto;
    }
    .folioImage {
      object-fit: cover;
      width: 100%;
      height: auto;
      overflow: hidden;
      box-shadow: 0 0 0 2rem var(--primaryBg);
      background: var(--primaryBg);
    }
  }
}

////// INDIVIDUAL pageYOffset
// Client1: Zendo
#client2_image0 {
  width: 100%;
  @include minMq($laptopBreak) {
    width: 70%;
  }
}

#client2_image0.false {
  width: 100%;
}

/* IMAGE CONTAINER */

@media all and (min-width: $mobileBreak) {
  .page_container .wrapper {
    width: 80vw;
  }

  .projectlist {
    @include flexbox();
  }

  .projectlist p:last-child {
    display: inline;
  }
}
