///////////////////////////////////////
//          INTRO SECTION            //
///////////////////////////////////////

// DESIGNER || DEVELOPER > LINKS //
// MOBILE FIRST <767px
body {
  /* Grid gap */
  --gap: 10px;
  --gap: 1.043vw;
  --grid-size: calc(100vw - 2.5vw);
}

////////////////////////////////////////
//            INTRO STYLES            //
////////////////////////////////////////

.introSection {
  background: var(--primaryBg);
  @include flexColumnCentered;
  @include justify-content(flex-start);
  position: relative;
  // height: 100vh;
  top: 15vh;
  width: 100vw;
  margin: 0 auto;
  z-index: 1;
  overflow: hidden;
  @include minMq($laptopBreak) {
    top: 0;
  }
  & .introPage {
    @include flexbox();
    @include flex-direction(column);
    // height: 80vh;
    width: 90vw;
    justify-content: center;
    align-content: center;
    grid-gap: var(--gap);
    margin: 0 auto;
    position: relative;
    @include minMq($laptopBreak) {
      display: grid;
      margin-top: 0;
      // height: 100vh;
      width: 80vw;
      grid-template-columns: repeat(24, 2.75vw);
      grid-template-rows: repeat(16, 2.75vw);
    }
    // BUTTONS SWITCH DESKTOP//////////////////////////////
    & .desDev {
      @include flexRowCentered;
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      // top: 7vh;
      z-index: 1000;
      color: $secondary-color;
      text-decoration: none;
      @include transition(transform, 0.15s, ease-in-out);
      overflow: hidden;
      @include minMq($laptopBreak) {
        grid-area: 4 / 1 / 5 / 24;
        height: 3rem;
        top: 5vh;
      }
      button {
        background: none;
        outline: none;
        border: none;
        height: 100%;
        position: relative;
        @include H5-Header;
        @include flexbox();
        @include align-items(center);
        @include transition(transform, 0.15s, ease-in-out);
        color: var(--reverseOutText);
        margin: 0 0.5rem;
        transform: translateY(0%);
        cursor: pointer;
      }
      .developer,
      .designer {
        padding: 0.1rem 1rem 0.1rem 1rem;
        &:hover {
          @include transition(transform, 0.2s, ease-in-out);
          transition-delay: 0.1s;
          text-decoration: none;
          cursor: pointer;
          transform: translateY(-100%);
        }
        &.active {
          color: var(--primaryColor);
        }
        &::before {
          @include pseudoContent;
          @include H5-Header;
          display: flex;
          align-items: center;
          padding: 0.1rem 1rem 0.1rem 1rem;
          background: var(--primaryColor);
          color: white;
          overflow: hidden;
          transform: translateY(100%);
          @include transition(transform, 0.1s, ease-in-out);
        }
      }
      .developer::before {
        content: "Developer";
      }
      .designer::before {
        content: "Designer";
      }
      &__divider {
        font-size: clamp(1.4rem, 1vw, 1.6rem);
        line-height: 1.5;
        color: var(--primaryColor);
      }
    }

    & // DEVELOPER SECTION
	.textContainer {
      @include flexbox();
      @include flex-direction(column);
      position: relative;
      margin: 0;
      padding: 0;
      text-align: left;
      // top: 10vh;
      margin-top: 6rem;
      margin-bottom: 4rem;
      z-index: 1;
      @include minMq($laptopBreak) {
        top: 0;
        margin-top: 0;
      }
      &__Header {
        @include H1-Header;
        line-height: 1.2;
        letter-spacing: 0.25s;
        overflow: hidden;
        padding-left: 5vw;
        @include minMq($laptopBreak) {
          padding-left: 2vw;
        }
      }
      &__BodyText {
        opacity: 0;
        margin-top: 3rem;
        @include BodyTextLarge;
        display: none;
        padding-left: 5vw;
        @include minMq($laptopBreak) {
          padding-left: 2vw;
        }
        &.Body--Dev {
          text-align: right;
        }
      }
    }
    & .greyBlock {
      position: absolute;
      top: 8vh;
      height: 60vh;
      width: 70%;
      left: 20%;
      background: var(--accentGrey);
      z-index: 0;
      @include minMq($laptopBreak) {
        position: relative;
        top: 5vh;
        width: auto;
        height: auto;
        left: 0;
        width: 100%;
      }
    }
    .projectBlock {
      position: relative;
      display: none;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: 20px;
      margin: auto;
      text-align: center;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
      transition: all 0.5s ease-in;
      transform: translateY(-50%);
      opacity: 0;
      cursor: pointer;
      // background: var(--primaryColor);
      box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.15);
      background: rgba(var(--primaryColor-rgb), 0.5);
      transition: all 0.25s ease-in-out;

      @include minMq($laptopBreak) {
        display: flex;
      }
      &::before {
        @include pseudoContent();
        background-color: black;
        background-image: linear-gradient(
          43deg,
          #4158d0 0%,
          #c850c0 46%,
          #ffcc70 100%
        );
        opacity: 0.25;
        border-radius: 20px;
        z-index: -1;
      }
      &:hover {
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.75) 0%,
          transparent 100%
        );

        transition: all 0.25s ease-in-out;
      }
    }
    .frontEndSection {
      height: 100%;
      padding: 1.5rem 3rem;
      @include flexColumnCentered;
      & ul li {
        list-style-type: none;
      }
      .headerIntro {
        color: var(--primaryColor);
        color: white;
        font-size: clamp(1.6rem, 1.25vw, 1.8rem);
        line-height: 1.25;
        margin: 0 auto;
        margin-bottom: 1.6rem;
      }
      .subHeaderIntro {
        color: var(--primaryColor);
        font-size: clamp(1.4rem, 1.25vw, 1.5rem);
        line-height: 1.25;
        margin-bottom: 0.5rem;
      }
      .bodyTextIntro {
        margin-bottom: 0.5rem;
        font-size: clamp(1.4rem, 1.25vw, 1.5rem);
        line-height: 1.35;
        color: var(--bodyText);
      }
    }
    &.desIntro {
      .greyBlock {
        grid-area: 5 / 5 / 13 / 17;
        transform: translateX(-60%);
        opacity: 0;
        animation: 0.75s desBlockSlideIn 0.2s ease-in-out forwards;
      }
      .textContainer {
        grid-area: 7 / 3 / 18 / 13;
      }
      .Header--design {
        transform: translateX(25%);
        animation: 0.75s slidingIn 0.2s ease-in-out forwards;
        opacity: 0;
        border-left: 1px solid var(--primaryText);
        // display: block;
      }
      .Body--Design {
        transform: translateY(30%);
        opacity: 0;
        animation: $fadeInAnimation;
        display: block;
      }
      // PROFILE IMG BLOCK
      .profileImage {
        opacity: 0;
        grid-area: 7 / 15 / 11 / 19;
        overflow: hidden;
        position: relative;
        border: none;
        transform: translateY(-20%);
        animation: $fadeInAnimation;
        border: 2px solid var(--primaryColor);
        z-index: 300;
        img {
          opacity: 1;
          position: absolute;
        }
      }
      // FRONT END BLOCK
      .frontEndStack {
        opacity: 0;
        display: flex;
        text-align: center;
        transform: translateY(20%);
        animation: $fadeInAnimation;
        animation-delay: 0.75s;
        animation-duration: 0.6s;
        margin-bottom: 3rem;
        & .headerIntro {
          margin-bottom: 0.5rem;
        }
        @include minMq($laptopBreak) {
          grid-area: 7 / 14 / 12 / 18;
          margin-bottom: 0;
        }
      }
      // BACK END BLOCK
      .backEndStack {
        opacity: 0;
        display: flex;
        animation: $fadeInAnimation;
        transform: translateY(-20%);
        animation-delay: 0.75s;
        animation-duration: 0.6s;
        margin-bottom: 25rem;
        & .headerIntro {
          margin-bottom: 0.5rem;
        }
        @include minMq($laptopBreak) {
          grid-area: 9 / 18 / 14 / 22;
          margin-bottom: 0;
        }
      }
      // VIEW PROJECTS BLOCK
      .viewProjects {
        opacity: 0;
        @include flexColumnCentered;
        grid-area: 12 / 17 / 13/ 18;
        background: var(--primaryColor);
        animation: 1s fadeIn50 forwards 0.5s
          cubic-bezier(0.21, 0.81, 0.83, 0.67);
        // opacity: 1;
        border-radius: 100%;
        transform: translateY(0);
        @include maxMq($laptopBreak) {
          display: none;
        }
        // &:hover::before {
        //   content: "";
        //   position: absolute;
        //   top: 0;
        //   bottom: 0;
        //   left: 0;
        //   right: 0;
        //   width: 100%;
        //   height: 100%;
        //   background-image: linear-gradient(
        //     43deg,
        //     #4158d0 0%,
        //     #c850c0 46%,
        //     #ffcc70 100%
        //   );
        //   opacity: 0.75;
        //   border-radius: 100%;
        //   z-index: 0;
        //   transition: 0.25s background ease-in-out;
        // }
        &:hover {
          transform: translateY(5%);
          transition: 0.25s all ease-in-out;
          opacity: 1;
        }
        & .projectText {
          color: white;
          text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
          text-align: center;
          padding: 0;
          font-size: clamp(1rem, 1vw, 2.2rem);
          font-weight: 800;
          font-family: "Inter", sans-serif;

          &.iconMain {
            fill: white;
          }
        }
        svg {
          transform-origin: center;

          width: 3rem;
          & > .iconCenterFill {
            opacity: 0;
            transition: all 0.35s ease-in-out;
          }
          &:hover > .iconCenterFill {
            transition: all 0.35s ease-in-out;
            fill: var(--primaryColor);
            opacity: 1;
            cursor: pointer;
          }
          &:hover > #arrowDown {
            transition: all 0.35s ease-in-out;
            fill: white;
            opacity: 1;
            z-index: 3;
          }
        }
      }
    }

    // DES SECTION
    &.devIntro {
      .greyBlock {
        grid-area: 5 / 8 / 13 / 20;
        transform: translateX(60%);
        opacity: 0;
        animation: 0.75s devSlideIn 0.1s ease-in-out forwards;
      }
      .textContainer {
        grid-area: 7 / 12 / 18 / 22;
      }
      .Header--dev {
        transform: translateX(-25%);
        animation: 0.75s slidingInDev 0.2s ease-in-out forwards;
        display: block;
        opacity: 0;
        text-align: right;
        padding-right: 4vw;
        border-right: 1px solid var(--primaryText);
        @include minMq($laptopBreak) {
          padding-right: 2vw;
        }
      }
      .Body--Dev {
        transform: translateY(20%);
        animation: 0.35s slideUpDev 0.25s forwards;
        display: block;
        opacity: 0;
        padding-right: 2vw;
      }
      // PROFILE profileCircle
      .profileCircle {
        position: absolute;
        top: -55%;
        right: -55%;
        animation: $fadeInAnimation;
        animation-delay: 0.75s;
        width: 800px;
        height: 800px;
        background: rgba(var(--primaryColor-rgb), 0.1);
        border: none;
        border-radius: 50%;
        opacity: 0.1;
        z-index: -2;
      }
      // DESIGNER BLOCK
      .blockDes {
        display: flex;
        align-items: flex-start;
        transform: translateY(20%);
        animation: $fadeInAnimation;
        animation-duration: 0.6s;
        margin-bottom: 3rem;

        @include minMq($laptopBreak) {
          margin-bottom: 0;
          grid-area: 6 / 2 / 14 / 7;
        }
        @include minMq($desktopBreak) {
          grid-area: 6 / 3 / 12 / 7;
        }
      }
      // DEVELOPER BLOCK
      .blockDev {
        display: none;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateY(-20%);
        animation: $fadeInAnimation;
        animation-duration: 0.6s;
        margin-bottom: 15rem;
        @include minMq($laptopBreak) {
          grid-area: 8 / 7 / 16 / 12;
          margin-bottom: 0;
        }
        @include minMq($desktopBreak) {
          grid-area: 7 / 7 / 13 / 11;
        }
        .blockOne {
          opacity: 0;
          grid-area: 6 / 16 / 11 / 20;
          overflow: hidden;
          position: relative;
          border: none;
          transform: translateY(-50%);
          animation: 0.5s fadingIn 0.8s forwards ease-in-out;
          img {
            opacity: 1;
            position: absolute;
          }
        }
      }
      .blockDev,
      .blockDes {
        & .iconBorder {
          border-color: var(--iconFill);
        }
        & h3,
        & h4 {
          color: white;
        }
      }
    }

    // DES OUTRO //////////
    &.desOutro {
      .Header--design {
        display: none;
      }
      .Body--Design {
        display: none;
      }
      .blockOne {
        opacity: 0;
        display: block;
        transform: translateY(50%);
      }
      .blockTwo {
        opacity: 0;
        display: block;
        transform: translateY(-50%);
      }
    }

    // DEV OUTRO //////////
    &.devOutro {
      .blockOne {
        opacity: 1;
      }
      .blockTwo {
        opacity: 1;
      }
    }
  }
}

// REMOVE IMAGE BLOCKS ON MOBILE
.devIntro .blockOne,
.devIntro .blockTwo,
.devIntro .blockThree,
.devIntro .blockFour,
.desIntro .blockOne,
.desIntro .blockTwo,
.desIntro .blockThree,
.desIntro .blockFour {
  display: none;
}

// ICONS /////////////////////////
// FRONT END ICON ////////////////
.iconBorder {
  transform-origin: center;
  border: 1px solid var(--primaryColor);
  border-color: white;
  position: relative;
  width: 2rem;
  height: 2rem;
  padding: 2rem;
  border-radius: 100%;
  margin: 0 auto;
  margin-bottom: 1.5rem;
}
// ICONS /////////////////
.iconMain {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  fill: var(--bodyText);
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
}

.smallerHeader {
  color: var(--primaryColor);
  font-size: clamp(1.2rem, 1.25vw, 1.4rem);
  line-height: 1.25;
}

// NEXT SECTION BUTTON /////////////////
.introSection .downButton {
  bottom: 4.75%;
}

.topBG {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  overflow: hidden;
  // transform-origin: right bottom;
  clip-path: path(
    "M948.292,342.027l151.94-151.94
	c13.996-13.995,13.996-36.686,0-50.681c-12.177-12.178-30.936-13.754-44.822-4.74L669.538,520.538
	c-13.995,13.995-36.685,13.995-50.681,0c-13.994-13.995-13.994-36.686,0-50.681l235.655-235.655
	c3.339-6.423,2.313-14.525-3.077-19.916c-6.657-6.657-17.451-6.657-24.109,0L597.071,444.452c-6.658,6.657-17.452,6.657-24.11,0
	c-6.657-6.658-6.657-17.453,0-24.11l225.385-225.475c9.298-9.298,9.298-24.373,0-33.672c-9.298-9.297-24.373-9.298-33.671,0
	l-321.56,321.435c-9.298,9.298-24.373,9.298-33.671,0c-9.297-9.297-9.297-24.373,0-33.671l228.412-230.077
	c3.217-3.217,3.217-8.434,0-11.65c-3.218-3.217-8.434-3.217-11.65,0L514.947,318.447c-3.218,3.217-8.434,3.217-11.651,0
	c-3.216-3.217-3.216-8.433,0-11.649l67.433-67.433L0.725,0.146h1202.921v504.84L952.768,399.698
	C952.768,399.698,905.573,380.84,948.292,342.027z"
  );

  animation: 20.25s bendyBackdrop forwards infinite ease-in-out;
  height: 100%;
  background: linear-gradient(
    rgba(var(--primaryColor-rgb), 0.3) 0%,
    rgba(30, 19, 87, 0.05) 28%
  );
}
@keyframes bendyBackdrop {
  0% {
    clip-path: path(
      "M948.292,342.027l151.94-151.94
	c13.996-13.995,13.996-36.686,0-50.681c-12.177-12.178-30.936-13.754-44.822-4.74L669.538,520.538
	c-13.995,13.995-36.685,13.995-50.681,0c-13.994-13.995-13.994-36.686,0-50.681l235.655-235.655
	c3.339-6.423,2.313-14.525-3.077-19.916c-6.657-6.657-17.451-6.657-24.109,0L597.071,444.452c-6.658,6.657-17.452,6.657-24.11,0
	c-6.657-6.658-6.657-17.453,0-24.11l225.385-225.475c9.298-9.298,9.298-24.373,0-33.672c-9.298-9.297-24.373-9.298-33.671,0
	l-321.56,321.435c-9.298,9.298-24.373,9.298-33.671,0c-9.297-9.297-9.297-24.373,0-33.671l228.412-230.077
	c3.217-3.217,3.217-8.434,0-11.65c-3.218-3.217-8.434-3.217-11.65,0L514.947,318.447c-3.218,3.217-8.434,3.217-11.651,0
	c-3.216-3.217-3.216-8.433,0-11.649l67.433-67.433L0.725,0.146h1202.921v504.84L952.768,399.698
	C952.768,399.698,905.573,380.84,948.292,342.027z"
    );
  }
  50% {
    clip-path: path(
      "M948.293,342.027l209.416-209.416
	c13.996-13.995,13.996-36.685,0-50.681c-12.178-12.178-30.936-13.753-44.822-4.74L453.219,736.858
	c-13.996,13.995-36.686,13.995-50.682,0c-13.994-13.995-13.994-36.686,0-50.681l497.375-497.374
	c3.338-6.423,2.314-14.525-3.078-19.915c-6.656-6.657-17.451-6.658-24.109,0L519.66,521.862c-6.658,6.658-17.453,6.658-24.111,0
	c-6.656-6.657-6.656-17.452,0-24.11l246.768-246.855c9.297-9.298,9.297-24.374,0-33.673c-9.298-9.297-24.374-9.297-33.671,0
	L378.678,547.065c-9.297,9.299-24.371,9.299-33.67,0c-9.297-9.297-9.297-24.372,0-33.67l366.929-368.596
	c3.217-3.217,3.217-8.433,0-11.65c-3.217-3.216-8.432-3.218-11.65,0L452.551,380.842c-3.217,3.217-8.432,3.217-11.65,0
	c-3.217-3.217-3.217-8.434,0-11.65l129.828-129.827L0.724,0.146h1202.922v504.84L952.768,399.698
	C952.768,399.698,905.574,380.84,948.293,342.027z"
    );
  }
  100% {
    clip-path: path(
      "M948.292,342.027l151.94-151.94
	c13.996-13.995,13.996-36.686,0-50.681c-12.177-12.178-30.936-13.754-44.822-4.74L669.538,520.538
	c-13.995,13.995-36.685,13.995-50.681,0c-13.994-13.995-13.994-36.686,0-50.681l235.655-235.655
	c3.339-6.423,2.313-14.525-3.077-19.916c-6.657-6.657-17.451-6.657-24.109,0L597.071,444.452c-6.658,6.657-17.452,6.657-24.11,0
	c-6.657-6.658-6.657-17.453,0-24.11l225.385-225.475c9.298-9.298,9.298-24.373,0-33.672c-9.298-9.297-24.373-9.298-33.671,0
	l-321.56,321.435c-9.298,9.298-24.373,9.298-33.671,0c-9.297-9.297-9.297-24.373,0-33.671l228.412-230.077
	c3.217-3.217,3.217-8.434,0-11.65c-3.218-3.217-8.434-3.217-11.65,0L514.947,318.447c-3.218,3.217-8.434,3.217-11.651,0
	c-3.216-3.217-3.216-8.433,0-11.649l67.433-67.433L0.725,0.146h1202.921v504.84L952.768,399.698
	C952.768,399.698,905.573,380.84,948.292,342.027z"
    );
  }
}
.projectBlock {
  &::after {
    @include pseudoContent();
    transition: 0.35s all cubic-bezier(0.21, 0.81, 0.83, 0.67);
    background: url("/images/general/photo_profile.jpg");
    background: var(--primary-color);
    opacity: 1;
    background-blend-mode: overlay;
    background-size: cover;
    background-position: right center;
    border-radius: 20px;
    opacity: 0;
  }
  &:hover::after {
    transition: 0.35s all cubic-bezier(0.21, 0.81, 0.83, 0.67);
    opacity: 0.3;
  }
  .circles {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    z-index: -1;
    transform-origin: center;
    .circleBig,
    .circleMedium,
    .circleSmall {
      transform-origin: center;

      fill: rgba(270, 270, 270, 0.15);
    }
    .circleMedium {
      fill: rgba(270, 270, 270, 0.05);
    }
    .circleBig {
      transform: scale(1);
      transition: 0.35s scale cubic-bezier(0.21, 0.81, 0.83, 0.67);
    }
  }
  &:hover .iconBorder {
    transition: 0.35s all cubic-bezier(0.21, 0.81, 0.83, 0.67);
    transform: scale(1.22);
  }
  &:hover .circles {
    transition: 0.25s all cubic-bezier(0.21, 0.81, 0.83, 0.67);
    transform: translate(-50%, -50%);
    .circleBig {
      transition: 0.35s scale cubic-bezier(0.21, 0.81, 0.83, 0.67);
      opacity: 1;
      transform: scale(2.15);
      fill: rgba(270, 270, 270, 0.075);
    }
    .circleMedium {
      transition: 0.45s all cubic-bezier(0.21, 0.81, 0.83, 0.67);
      transform: scale(1.25);
      opacity: 1;
      fill: rgba(270, 270, 270, 0.05);
    }
    .circleSmall {
      transition: 0.15s all cubic-bezier(0.21, 0.81, 0.83, 0.67);
      transform: scale(1.75);
      opacity: 1;
      fill: rgba(270, 270, 270, 0.095);
    }
  }
}

@-webkit-keyframes zoomIn {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.22);
  }
}
@-webkit-keyframes zoomOut {
  0% {
    transform: scale(1.22);
  }
  100% {
    transform: scale(1);
  }
}

// .projectBlock {
//   -webkit-animation: zoomIn 1s;
// }
//
// .projectBlock:hover {
//   -webkit-animation: zoomOut 1s;
// }
//
// @-webkit-keyframes zoomIn {
//   0% {
//     -webkit-transform: scale(1);
//   }
//   100% {
//     -webkit-transform: scale(1.2);
//   }
// }
//
// @-webkit-keyframes zoomOut {
//   0% {
//     -webkit-transform: scale(1.2);
//   }
//   100% {
//     -webkit-transform: scale(1);
//   }
// }
