// SUBHEADER NAV /////////////////////
.subheader {
  position: fixed;
  z-index: 499;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  -webkit-transition: all 0.3s ease-in;
  width: 80vw;
  transition: all 0.3s ease-in;
  @include minMq($laptopBreak) {
    z-index: 501;
  }
  & .wrapper {
    position: relative;
    margin: 0 auto;
    height: 4rem;
    opacity: 1;
    z-index: -1;
    // @include minMq($mobileBreak) {
    //   background: blue;
    // }
    & .subHeaderContainer {
      position: absolute;
      @include flexRowCentered;
      @include justify-content(space-between);
      z-index: 100;
      margin: 0 auto;
      width: 100%;
      border-bottom: 1px solid var(--primaryLine);
      padding: 10rem 0 1rem 0;
      @include transition(all, 0.3s, ease-in-out);
      background: var(--primaryHeaderBg);
      font-size: clamp(1.2rem, 2vw, 1.2rem);
      color: var(--secondaryText);
      @include minMq($mobileBreak) {
        padding-top: 8rem;
      }
      @include minMq($laptopBreak) {
        padding-top: 6rem;
      }
      .clients {
        @include flexRowCentered;
        & .projNum {
          font-size: clamp(1rem, 2vw, 1.2rem);
          background: var(--primaryColor);
          padding: 0.25rem 0.5rem;
          color: var(--primaryWhite);
          width: 2.25rem;
          text-align: center;
          margin: 0 auto;
          margin-right: 0.75rem;
        }
        & h5 {
          font-size: inherit;
          position: relative;
          font-weight: 700;
          @include transition(all, 0.3s, ease-in-out);
          & span {
            color: var(--primaryColor);
            border-right: 1px solid var(--primaryLine);
            padding-right: 0.5rem;
            margin-right: 0.75rem;
          }
        }
      }
      .nextLast {
        @include flexbox();
        @include justify-content(center);
        @include minMq($mobileBreak) {
          z-index: 1000;
          @include justify-content(center);
        }
        & button {
          text-decoration: none;
          z-index: 100;
          position: relative;
          margin-top: 0;
          font-size: inherit;
          font-weight: 700;
          opacity: 1;
          background: (0, 0, 0, 0);
          @include flexbox();
          @include justify-content(center);
          @include transition(all, 0.15s, ease-out);
          outline: none;
          border: none;
          color: var(--primaryButton);
          &:hover {
            opacity: 1;
            @include transition(all, 0.15s, ease-out);
            color: var(--primaryColor);
            cursor: pointer;
          }
          &:active {
            color: $light-accent-color;
          }
          &.next {
            padding-left: 1rem;
          }
          &.last {
            padding-right: 1rem;
            border-right: 1px solid var(--primaryLine);
          }
        }
      }
    }
  }
}

// .subheader.smaller {
//   .subHeaderContainer {
//     background: red !important;
//   }
// }
// subheader contracts on scroll down
.subheader.smaller {
  .subHeaderContainer {
    @include transition(all, 0.1s, ease-in-out);
    padding-top: 10rem;
    border-top: none;
    border-bottom: 3px solid var(--primaryColor);
    @include minMq($mobileBreak) {
      top: 0;
      padding-top: 8rem;
    }
  }
}
// makes "project details" text fade on scroll down
.smaller.subheader ~ .page_container section#portfolio > h5 {
  opacity: 0;
  transition: 0.3s all ease-in-out;
}
